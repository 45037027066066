import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import {
  Avatar,
  Button,
  FormControl,
  Grid,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LANGUAGES } from "app/utils/constants/settings";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import Galleries from "../media/galleries/Galleries";
import { getGallery } from "app/services/api/media";
import { CKEditor } from "ckeditor4-react";
import { CK_FONT_SIZE_OPTIONS } from "../presentation/module/form/types";

const STATUS_LIST = ["Displayed", "Hidden"];

const ArticleDetails = ({
  article: { text, gallery, visible_from, visible_to, created_at },
  onChange,
}) => {
  const [imageRatio, setImageRatio] = useState(1);
  const [initialized, setInitialized] = useState(false);
  const [image, setImage] = useState(false);
  const [galleryTitle, setGalleryTitle] = useState("");
  const [openGallerySelector, setOpenGallerySelector] = useState();
  const [article, setArticle] = useState({
    text,
    gallery,
    visible_from,
    visible_to,
    created_at,
  });
  const imageStyle = {
    height: "auto",
    width: `${(imageRatio || 1) * 150}px`,
    objectFit: "contain",
    cursor: "pointer",
  };
  useEffect(() => {
    if (onChange) {
      onChange(article);
    }
    if (article?.gallery) {
      getGallery(article?.gallery).then((response) => {
        setGalleryTitle(response?.label || "");
        const imageUrl = (response?.images && response?.images[0]?.path) || "";
        setImage(imageUrl);
      });
    } else {
      setImage("");
    }
  }, [article]);

  useEffect(() => {
    setArticle({ text, gallery, visible_from, visible_to, created_at });
  }, [text, gallery, visible_from, visible_to, created_at]);

  const { t } = useTranslation();

  const handleGallerySelect = (galleryItem) => {
    setOpenGallerySelector();
    setArticle({ ...article, gallery: galleryItem.url });
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75vw",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        display="flex"
        sx={{ mb: 2 }}
        alignItems="center"
      >
        <ImageListItem
          sx={{
            "& .MuiImageListItemBar-root": {
              transition: "visibility 0s, opacity 0.3s linear",
              opacity: 0,
            },

            "&:hover .MuiImageListItemBar-root": {
              visibility: "visible",
              opacity: 1,
            },
            width: "fit-content",
            height: "auto",
          }}
        >
          <Avatar
            src={image || "/images/pages/not_found.svg"}
            variant={"rounded"}
            sx={imageStyle}
            onLoad={(e) => {
              !initialized &&
                setImageRatio(e.target.offsetWidth / e.target.offsetHeight);
              setInitialized(true);
            }}
            onClick={() => setOpenGallerySelector(true)}
          />
          {article.gallery && (
            <ImageListItemBar
              subtitle={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography variant="body">{galleryTitle}</Typography>
                  <DeleteIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setArticle({ ...article, gallery: "" })}
                  />
                </Stack>
              }
            />
          )}
        </ImageListItem>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Grid container rowSpacing={2}>
            <Stack sx={{ width: "100%" }}>
              <Typography variant="body" my={1}>
                {t("pages.modules.textLabel")}
              </Typography>
              <CKEditor
                onChange={(event) => {
                  const data = event.editor.getData();
                  setArticle({ ...article, text: data });
                }}
                initData={article?.text || ""}
                config={{
                  contentsCss: [
                    `${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                    `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                  ],
                  extraPlugins: "font,colorbutton,justify,colordialog",
                  fontSize_sizes: CK_FONT_SIZE_OPTIONS,
                  colorButton_enableMore: true,
                  versionCheck: false,
                }}
              />
            </Stack>
            <Grid item xs={12} lg={2}>
              <TextField
                id="created_at"
                label={t("pages.modules.createdAt")}
                type="datetime-local"
                name="created_at"
                fullWidth
                value={article.created_at || ""}
                onChange={(e) =>
                  setArticle({ ...article, created_at: e.target.value })
                }
                sx={{ input: { px: 1 }, maxWidth: { lg: "160px" } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextField
                id="visible_from"
                label={t("pages.modules.visibleFrom")}
                type="datetime-local"
                name="visible_from"
                fullWidth
                value={article.visible_from || ""}
                onChange={(e) =>
                  setArticle({ ...article, visible_from: e.target.value })
                }
                sx={{ input: { px: 1 }, maxWidth: { lg: "160px" } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2} textAlign={{ xs: "start", lg: "end" }}>
              <TextField
                id="visible_to"
                label={t("pages.modules.visibleTo")}
                type="datetime-local"
                name="visible_to"
                fullWidth
                sx={{ input: { px: 1 }, maxWidth: { lg: "160px" } }}
                value={article.visible_to || ""}
                onChange={(e) =>
                  setArticle({ ...article, visible_to: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Modal
        open={openGallerySelector !== undefined}
        onClose={() => setOpenGallerySelector()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={modalStyle}>
          <Galleries
            selectorMode
            handleSelect={(image) => handleGallerySelect(image)}
          />
        </Div>
      </Modal>
    </>
  );
};

const ArticleForm = ({
  article,
  onAddArticle,
  onEditArticle,
  onCancel,
  categories,
}) => {
  const { t } = useTranslation();
  const [articleDetails, setArticleDetails] = useState({});
  const ArticleCategorySchema = Yup.object().shape({
    label: Yup.string().required(t("pages.articleCategories.labelRequired")),
    language: Yup.string().required(t("pages.articles.languageRequired")),
    category: Yup.string().required(t("pages.articles.categoryRequired")),
    status: Yup.string().required(t("pages.articles.statusRequired")),
  });

  const articleObj = article && { ...article, category: article.category.url };

  const handleArticleDetails = (details) => {
    setArticleDetails(details || {});
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={
        articleObj || { label: "", language: "", category: "", status: "" }
      }
      validationSchema={ArticleCategorySchema}
      onSubmit={async (data) => {
        const handler = article ? onEditArticle : onAddArticle;
        handler({ ...data, ...articleDetails });
      }}
    >
      <Form style={{ textAlign: "left" }} noValidate>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <JumboTextField
            fullWidth
            required
            name="label"
            label={t("pages.presentation.label")}
          />
        </Div>
        <FormControl sx={{ mt: 1, mb: 1, mx: 1, pl: 1, pr: 3 }} fullWidth>
          <JumboTextField
            select
            name="language"
            required
            label={t("pages.articles.language")}
          >
            {LANGUAGES.map((language, index) => {
              return (
                <MenuItem key={index} value={language.code}>
                  {language.name}
                </MenuItem>
              );
            })}
          </JumboTextField>
        </FormControl>
        <FormControl sx={{ mt: 1, mb: 1, mx: 1, pl: 1, pr: 3 }} fullWidth>
          <JumboTextField
            select
            name="category"
            required
            label={t("pages.articles.category")}
          >
            {categories?.map((category, index) => {
              return (
                <MenuItem key={index} value={category.url}>
                  {category.label}
                </MenuItem>
              );
            })}
          </JumboTextField>
        </FormControl>
        <FormControl sx={{ mt: 1, mb: 1, mx: 1, pl: 1, pr: 3 }} fullWidth>
          <JumboTextField
            select
            name="status"
            required
            label={t("pages.screens.status")}
          >
            {STATUS_LIST?.map((status, index) => {
              return (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              );
            })}
          </JumboTextField>
        </FormControl>
        <FormControl sx={{ mt: 1, mb: 1, mx: 1, pl: 1, pr: 3 }} fullWidth>
          <ArticleDetails
            onChange={handleArticleDetails}
            article={article || {}}
          />
        </FormControl>

        <Div
          sx={{
            mt: 2,
            mb: 1,
            mx: 2,
            gap: "4px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button size="normal" onClick={onCancel}>
            {t("buttons.cancel")}
          </Button>
          <Button type="submit" variant="contained" size="normal">
            {t("buttons.ok")}
          </Button>
        </Div>
      </Form>
    </Formik>
  );
};

export default ArticleForm;
