import { USE_IMAGE_PLACEHOLDERS } from "./constants/paths";
import { VAT_RATES_CZ, VAT_RATES_SK } from "./constants/settings";

export const getAssetPath = (url, size) => {
  if (USE_IMAGE_PLACEHOLDERS) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

/**
 *
 * @returns
 */
export const getVatRates = () => {
  const dsok = JSON.parse(localStorage.getItem("dsokSettings"));
  const vatCountryCode = dsok?.customer?.vatCountryCode || "sk";
  let vatRates = [];
  switch (vatCountryCode) {
    case "sk":
      vatRates = VAT_RATES_SK;
      break;
    case "cz":
      vatRates = VAT_RATES_CZ;
      break;
    default:
      vatRates = VAT_RATES_SK;
  }
  return vatRates;
};
