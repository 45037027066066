import Div from "@jumbo/shared/Div";
import { Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { useSearchParams } from "react-router-dom";
import InvalidTokenMessage from "./invalidTokenMessage";
import usePartnerAuth from "@jumbo/hooks/usePartnerAuth";
import activeLocale from "app/services/partner/activeLocale";
import manageToken from "./ManageToken";

const PartnerHome = () => {
  const { validate, user } = usePartnerAuth();
  const [validated, setValidated] = useState();
  const [tokenChecked, setTokenChecked] = useState();
  const [searchParams] = useSearchParams();
  const token = manageToken(searchParams);
  const lang = searchParams.get("hl");
  useEffect(() => {
    setTokenChecked(true);
    activeLocale(lang);
    if (token) {
      validate(token)
        .then((res) => {
          setValidated(true);
        })
        .catch((error) => {
          setValidated(false);
          console.log("Token validation failed", error);
        });
    }
  }, [token]);
  if (tokenChecked && !validated) {
    return InvalidTokenMessage();
  }
  return (
    <Div>
      <Typography variant="h2">{t("dsokPartner.welcomeText")}</Typography>
      <Grid container spacing={2} alignItems={"stretch"}></Grid>
      <img src={`${ASSET_IMAGES}/dsok/kiosk-detail.png`} alt="DSOK" />
    </Div>
  );
};

export default PartnerHome;
