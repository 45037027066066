import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { Grid, MenuItem } from "@mui/material";
import { Label } from "app/pages/presentation/module/form/types";
import {
  DSOK_TRANSACTION_TYPES,
  PORTOS_CUSTOMER_TYPES,
} from "app/utils/constants/settings";
import React from "react";

const PortosEkasa = ({ device, setFieldValue, values, t }) => {
  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.localPort`}
            label={t("pages.dsokDetails.localPort")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            name={`customer.devices.${device}.ekasa.serverPort`}
            label={t("pages.dsokDetails.serverPort")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.ip`}
            label={t("pages.dsokDetails.ip")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            required
            select
            InputLabelProps={{ required: false }}
            name={`customer.devices.${device}.ekasa.customer.type`}
            label={
              <Label
                text={t("pages.dsokDetails.customerType")}
                helpText={t("pages.dsokDetails.customerTypeHelp")}
                required
              />
            }
          >
            {PORTOS_CUSTOMER_TYPES.map(({ code, name }) => {
              return (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              );
            })}
          </JumboTextField>
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.customer.id`}
            label={
              <Label
                text={t("pages.dsokDetails.customerId")}
                helpText={t("pages.dsokDetails.customerIdHelp")}
                required
              />
            }
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            required
            name={`customer.devices.${device}.ekasa.dic`}
            label={t("pages.dsokDetails.dic")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={12} lg={12}></Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            required
            name={`customer.devices.${device}.ekasa.cashRegisterCode`}
            label={t("pages.dsokDetails.cashRegisterCode")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            required
            select
            InputLabelProps={{ required: false }}
            name={`customer.devices.${device}.ekasa.print.printerName`}
            label={<Label text={t("pages.dsokDetails.printerName")} required />}
          >
            <MenuItem key="1" value="email">
              email
            </MenuItem>
            <MenuItem key="2" value="pos">
              pos
            </MenuItem>
          </JumboTextField>
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            required
            select
            InputLabelProps={{ required: false }}
            name={`customer.devices.${device}.ekasa.transactionType`}
            label={
              <Label
                text={t("pages.dsokDetails.transactionType")}
                helpText={t("pages.dsokDetails.transactionTypeHelp")}
                required
              />
            }
          >
            {DSOK_TRANSACTION_TYPES.map(({ code, name }) => {
              return (
                <MenuItem key={code} value={code}>
                  {t(`pages.dsokDetails.${name}`)}
                </MenuItem>
              );
            })}
          </JumboTextField>
        </Div>
      </Grid>
      <Grid item xs={12} md={12} lg={12}></Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            type="string"
            InputLabelProps={{ required: false }}
            required
            fullWidth
            name={`customer.devices.${device}.ekasa.print.paperState`}
            label={<Label text={t("pages.dsokDetails.paperState")} required />}
            InputProps={{
              value: values.customer.devices[device].ekasa.print.paperState,
              readOnly: true,
              onChange: (e) =>
                setFieldValue(
                  `customer.devices.${device}.ekasa.print.paperState`,
                  e.target.value
                ),
            }}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.eKasa.serialNumber`}
            label={t("pages.dsokDetails.eKasaSerialNumber")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={12} lg={12}></Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.headerText`}
            label={t("pages.dsokDetails.headerText")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.footerText`}
            label={t("pages.dsokDetails.footerText")}
          />
        </Div>
      </Grid>
    </>
  );
};

export default PortosEkasa;
