import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useFormikContext } from "formik";
import { ColorField, Label } from "app/pages/presentation/module/form/types";
import * as Yup from "yup";
import Div from "@jumbo/shared/Div";

const FIELD_NAME = "UI_SETTINGS_FIELD";

export const UI_SETTINGS_FIELD_SCHEMA = {
  welcomeTextSize: 58,
  welcomeTextColor: "#f0f0f0",
  buttonBackgroundColor: "#de4f44",
  buttonFontColor: "#f0f0f0",
  fontColorPrimary: "#1A1A1A",
  fontColorSecondary: "#de4f44",
  priceColor: "#de4f44",
  priceListSize: 16,
};

export const getUISettingsFieldValidationSchema = (t) => ({
  welcomeTextSize: Yup.number().required(t("messages.checkRequiredFields")),
  priceListSize: Yup.number().required(t("messages.checkRequiredFields")),
  welcomeTextColor: Yup.string().required(t("messages.checkRequiredFields")),
  buttonBackgroundColor: Yup.string().required(
    t("messages.checkRequiredFields")
  ),
  buttonFontColor: Yup.string().required(t("messages.checkRequiredFields")),
  fontColorPrimary: Yup.string().required(t("messages.checkRequiredFields")),
  fontColorSecondary: Yup.string().required(t("messages.checkRequiredFields")),
  priceColor: Yup.string().required(t("messages.checkRequiredFields")),
});

const colorFieldStyle = {
  mt: 0,
  mb: 0,
  mx: 0,
  div: { borderColor: "rgba(0, 0, 0, 0.23)" },
};

const UISettingsField = ({ handleChange, expanded }) => {
  const { t, i18n } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.uiSettings")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("pages.dsokDetails.uiSettingsSections.welcomeScreen")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                required
                fullWidth
                name={`uiSettings.welcomeTextSize`}
                label={t("pages.dsokDetails.welcomeTextSize")}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.welcomeTextColor"
                lang={i18n.language}
                parameters={{
                  label: {
                    [i18n.language]: t("pages.dsokDetails.welcomeTextColor"),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("pages.dsokDetails.uiSettingsSections.general")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.fontColorPrimary"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t("pages.dsokDetails.fontColorPrimary"),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.fontColorSecondary"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t("pages.dsokDetails.fontColorSecondary"),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.spinnerColor"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.spinnerColor"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("pages.dsokDetails.uiSettingsSections.buttons")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.buttonBackgroundColor"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.buttonBackgroundColorActive"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.buttonFontColor"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.buttonFontColorActive"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.buttonBackgroundColorHomePage"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.buttonBackgroundColorHomePage"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.buttonFontColorHomePage"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.buttonFontColorHomePage"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("pages.dsokDetails.uiSettingsSections.prices")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                required
                fullWidth
                name={`uiSettings.priceListSize`}
                label={t("pages.dsokDetails.uiSettingsSections.priceListSize")}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.priceColor"
                lang={i18n.language}
                parameters={{
                  label: {
                    [i18n.language]: t("pages.dsokDetails.priceColor"),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("pages.dsokDetails.uiSettingsSections.icons")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.successIconFontColor"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.successIconFontColor"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.successIconBackgroundColor"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.successIconBackgroundColor"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.errorIconFontColor"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.errorIconFontColor"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <ColorField
                name="uiSettings.errorIconBackgroundColor"
                lang={i18n.language}
                sx={colorFieldStyle}
                parameters={{
                  label: {
                    [i18n.language]: t(
                      "pages.dsokDetails.uiSettingsSections.errorIconBackgroundColor"
                    ),
                  },
                  required: true,
                }}
              />
            </Div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UISettingsField;
