import React from "react";
import {
  Grid,
  Typography,
  Divider,
  FormControlLabel,
  MenuItem,
  Alert,
} from "@mui/material";
import { Field } from "formik";
import Switch from "@mui/material/Switch";
import Div from "@jumbo/shared/Div";
import { Label } from "app/pages/presentation/module/form/types";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { DSOK_EKASA_TYPES } from "app/utils/constants/settings";
import IKelpPosManagerPosPrinter from "./eKasa/IKelpPosManagerPosPrinter";
import PortosEkasa from "./eKasa/PortosEkasa";
import IKelpPosManagerSk from "./eKasa/IKelpPosManagerSk";

const EkasaSection = ({ device, setFieldValue, values, t }) => {
  const eKasaSupplier = values.customer.devices[device].ekasa?.eKasaType;
  let info;
  switch (eKasaSupplier) {
    case "portos_sk":
      info = t("pages.dsokDetails.infoTexts.eKasa.portos");
      break;
    case "fiskalPro_sk":
      info = t("pages.dsokDetails.infoTexts.eKasa.fiskalPro");
      break;
    case "iKelpPosManager_sk":
      info = t("pages.dsokDetails.infoTexts.eKasa.iKelpPosManager");
      break;
    case "iKelpPosManagerPosPrinter":
      info = t("pages.dsokDetails.infoTexts.eKasa.iKelpPosManagerPosPrinter");
      break;
    default:
      info = "";
  }
  const eKasaEnabled =
    values.customer.devices[device].orderProcess?.enableEkasa;
  const ekasaHttpsEnabled = values.customer.devices[device].ekasa?.useHTTPS;

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.ekasa")}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
          <FormControlLabel
            control={
              <Field
                name={`customer.devices.${device}.orderProcess.enableEkasa`}
                component={Switch}
                onChange={(e) =>
                  setFieldValue(
                    `customer.devices.${device}.orderProcess.enableEkasa`,
                    e.target.checked
                  )
                }
              />
            }
            label={
              <Label
                text={t("pages.dsokDetails.enableEkasa")}
                color="primary"
                helpText={t("pages.dsokDetails.enableEkasaHelp")}
              />
            }
            checked={values.customer.devices[device].orderProcess?.enableEkasa}
          />
        </Div>
      </Grid>
      {eKasaEnabled && (
        <>
          <Grid item xs={12} md={4} lg={4}>
            <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
              <FormControlLabel
                control={
                  <Field
                    name={`customer.devices.${device}.ekasa.useHTTPS`}
                    component={Switch}
                    onChange={(e) =>
                      setFieldValue(
                        `customer.devices.${device}.ekasa.useHTTPS`,
                        e.target.checked
                      )
                    }
                  />
                }
                label={t("pages.dsokDetails.useHTTPS")}
                checked={ekasaHttpsEnabled}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                required
                select
                InputLabelProps={{ required: false }}
                name={`customer.devices.${device}.ekasa.eKasaType`}
                label={
                  <Label text={t("pages.dsokDetails.eKasaType")} required />
                }
              >
                {DSOK_EKASA_TYPES.map(({ code, name }) => {
                  return (
                    <MenuItem key={code} value={code}>
                      {t(`pages.dsokDetails.${name}`)}
                    </MenuItem>
                  );
                })}
              </JumboTextField>
            </Div>
          </Grid>
          {info && (
            <Grid item xs={12} md={12} lg={12}>
              <Alert variant="outlined" severity="info">
                {info}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}></Grid>
          {eKasaSupplier && eKasaSupplier === "portos_sk" && (
            <PortosEkasa
              device={device}
              setFieldValue={setFieldValue}
              values={values}
              t={t}
            />
          )}
          {eKasaSupplier && eKasaSupplier === "iKelpPosManager_sk" && (
            <IKelpPosManagerSk
              device={device}
              setFieldValue={setFieldValue}
              values={values}
              t={t}
            />
          )}
          {eKasaSupplier && eKasaSupplier === "iKelpPosManagerPosPrinter" && (
            <IKelpPosManagerPosPrinter
              device={device}
              setFieldValue={setFieldValue}
              values={values}
              t={t}
            />
          )}
        </>
      )}
    </>
  );
};

export default EkasaSection;
