import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Link,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Field } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

const ProductItem = ({
  item,
  disabled,
  onDelete,
  onEdit,
  defaultLanguage,
  productCategory,
  selectMode,
  onItemUpdate,
  selectedProducts,
}) => {
  const { t, i18n } = useTranslation();
  const categoryName = productCategory?.name[defaultLanguage || i18n.language];
  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
              justifyContent: "space-between",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              color: "text.secondary",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
          }}
        >
          <Div sx={{ flex: { xs: "0 1 auto", md: 1 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.products.image")}
            </Typography>
            <Typography variant={"body1"}>
              <img
                src={item.customProductImageUrl || item.image || ""}
                width={75}
              />
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: "0 1 auto", md: 3 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.presentation.label")}
            </Typography>
            <Typography variant={"body1"}>
              {/* <Link onClick={onEdit}> */}
              {(item?.productName &&
                item?.productName[defaultLanguage || i18n.language]) ||
                ""}
              {/* </Link> */}
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: "0 1 auto", md: 0.5 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.screens.status")}
            </Typography>
            <Typography variant={"body1"}>
              {item?.isActive
                ? t("pages.products.active")
                : t("pages.products.disabled")}
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: "0 1 auto", md: 0.5 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.dsokCategories.menuType")}
            </Typography>
            <Typography variant={"body1"}>
              {productCategory?.menuType}
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: "0 1 auto", md: 0.5 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.dsokCategories.category")}
            </Typography>
            <Typography variant={"body1"}>{categoryName}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: 0.5 },
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.products.price")}
            </Typography>
            <Typography variant={"body1"}>{item?.price}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: 0.5 },
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.products.vatRate")}
            </Typography>
            <Typography variant={"body1"}>
              {item?.vatRate ? item?.vatRate + "%" : ""}
            </Typography>
          </Div>
          {selectMode && (
            <>
              <Div
                sx={{ flex: { xs: "0 1 auto", md: 1 }, flexShrink: 0, px: 1 }}
              >
                <JumboTextField
                  type="number"
                  fullWidth
                  name="null"
                  defaultValue={selectedProducts[item._id]?.customPrice || 0}
                  value={selectedProducts[item._id]?.customPrice || 0}
                  label={t("pages.dsokProducts.customPrice")}
                  InputLabelProps={{ required: false }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 0.01,
                    },
                    onChange: (e) =>
                      onItemUpdate(item._id, "customPrice", e.target.value),
                  }}
                />
              </Div>
              <Div
                sx={{ flex: { xs: "0 1 auto", md: 1 }, flexShrink: 0, px: 1 }}
              >
                <JumboTextField
                  type="number"
                  fullWidth
                  name="null"
                  defaultValue={selectedProducts[item._id]?.qty || 0}
                  value={selectedProducts[item._id]?.qty || 0}
                  label={t("pages.dsokOrders.quantity")}
                  InputLabelProps={{ required: false }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    onChange: (e) =>
                      onItemUpdate(item._id, "qty", e.target.value),
                  }}
                />
              </Div>
              <Div
                sx={{ flex: { xs: "0 1 auto", md: 0.5 }, flexShrink: 0, px: 1 }}
              >
                <Typography
                  fontSize={"12px"}
                  variant={"h6"}
                  color={"text.secondary"}
                  mb={0.25}
                  sx={{
                    display: { xs: "none", md: "block" },
                  }}
                >
                  {t("pages.dsokProducts.autoAdd")}
                </Typography>
                <Div sx={{ mt: 0, mb: 0, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        onChange={(e) =>
                          onItemUpdate(item._id, "autoAdd", e.target.checked)
                        }
                      />
                    }
                    checked={selectedProducts[item._id]?.autoAdd || false}
                  />
                </Div>
              </Div>
            </>
          )}
          <Div
            sx={{
              flex: { xs: "0 2 auto", md: 0 },
              px: 1,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {selectMode
                ? t("pages.products.active")
                : t("pages.presentation.actions")}
            </Typography>
            <Div
              sx={{
                display: "flex",
                flex: { direction: "row", flexWrap: "wrap" },
                minWidth: 70,
              }}
            >
              <Div>
                {selectMode ? (
                  <>
                    <Div sx={{ mt: 0, mb: 0, mx: 0.5 }}>
                      <FormControlLabel
                        control={
                          <Field
                            // name={`include_in_menu`}
                            component={Switch}
                            onChange={(e) =>
                              onItemUpdate(item._id, "status", e.target.checked)
                            }
                          />
                        }
                        checked={selectedProducts[item._id]?.status || false}
                      />
                    </Div>
                  </>
                ) : (
                  <>
                    <IconButton
                      size="small"
                      variant={"contained"}
                      disabled={disabled}
                      onClick={onEdit}
                    >
                      <Tooltip title={t("buttons.edit")} placement="top-start">
                        <EditIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      size="small"
                      variant={"contained"}
                      disabled={disabled}
                      onClick={() => onDelete(item)}
                    >
                      <Tooltip
                        title={t("buttons.delete")}
                        placement="top-start"
                      >
                        <DeleteForeverIcon />
                      </Tooltip>
                    </IconButton>
                  </>
                )}
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};
/* Todo item prop define */
export default ProductItem;
