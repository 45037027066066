const manageToken = (searchParams) => {
  const queryToken = searchParams.get("token");
  if (queryToken) {
    localStorage.setItem("PARTNER_TOKEN", queryToken);
  }
  return queryToken !== null && queryToken !== undefined
    ? queryToken
    : localStorage.getItem("PARTNER_TOKEN");
};
export default manageToken;
