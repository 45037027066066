import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { Grid } from "@mui/material";
import { Label } from "app/pages/presentation/module/form/types";
import React from "react";

const IKelpPosManagerSk = ({ device, t }) => {
  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.ip`}
            label={t("pages.dsokDetails.ip")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.localPort`}
            label={t("pages.dsokDetails.localPort")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            name={`customer.devices.${device}.ekasa.serverPort`}
            label={t("pages.dsokDetails.serverPort")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            InputLabelProps={{ required: false }}
            name={`customer.devices.${device}.ekasa.code`}
            label={
              <Label
                text={t("pages.dsokDetails.eKasaCode")}
                color="primary"
                helpText={t("pages.dsokDetails.posCodeHelpText")}
                required
              />
            }
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={12} lg={12}></Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.headerText`}
            label={t("pages.dsokDetails.headerText")}
          />
        </Div>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Div sx={{ mt: 1, mb: 1 }}>
          <JumboTextField
            fullWidth
            name={`customer.devices.${device}.ekasa.footerText`}
            label={t("pages.dsokDetails.footerText")}
          />
        </Div>
      </Grid>
    </>
  );
};

export default IKelpPosManagerSk;
