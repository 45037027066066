import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Stack,
  Switch,
  Tab,
  Typography,
  alpha,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  CK_FONT_SIZE_OPTIONS,
  UploadComponent,
} from "app/pages/presentation/module/form/types";
import { CKEditor } from "ckeditor4-react";
import DSOKCategoryCheckbox from "./DSOKCategoryCheckbox";
import DSOKProducts from "./DSOKProducts";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboSearch from "@jumbo/components/JumboSearch";
import BindingItems from "./BindingItems";
import { arrayMove } from "react-sortable-hoc";
import { getVatRates } from "app/utils/appHelpers";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "75vw",
  maxHeight: "70vh",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PRODUCT_DETAIL_TABS = ["main", "prices", "categories", "bindings"];

const DSOKProductForm = ({
  product,
  onAddProduct,
  onEditProduct,
  onCancel,
  productLanguages,
  defaultLanguage,
  categories,
  locations,
  products,
  title,
}) => {
  const { t, i18n } = useTranslation();
  const DSOKProductSchema = Yup.object().shape({
    productName: Yup.object().required(t("pages.dsokCategories.nameRequired")),
  });
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const [allProducts, setAllProducts] = useState({});
  const [openModal, setOpenModal] = useState();
  const [selectedTab, setSelectedTab] = useState("main");
  const { showDialog, hideDialog } = useJumboDialog();
  const [searchName, setSearchName] = useState();
  const [onlyActiveCategories, setOnlyActiveCategories] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const handleDelete = (key, values, setFieldValue) => {
    const bindings = { ...values.bindings };
    delete bindings[key];
    setFieldValue("bindings", {
      ...bindings,
    });
    hideDialog();
  };

  useEffect(() => {
    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage);
    }
  }, [defaultLanguage]);

  useEffect(() => {
    if (products?.length) {
      const productMap = {};
      products.forEach((product) => (productMap[product._id] = product));
      setAllProducts(productMap);
    }
  }, [products]);

  useEffect(() => {
    if (categories?.length) {
      let filteredCategories = searchName
        ? categories.filter(
            (category) =>
              category?.name &&
              category.name[defaultLanguage].toLowerCase().includes(searchName)
          )
        : categories;
      if (onlyActiveCategories) {
        filteredCategories = filteredCategories.filter((category) =>
          product?.categories ? product?.categories[category._id] : false
        );
      }
      setFilteredCategories(filteredCategories || []);
    }
  }, [searchName, categories, onlyActiveCategories]);

  const handleAddGroup = (code, values, setFieldValue) => {
    setFieldValue("bindings.name", values.bindings[code].name);
    setFieldValue("bindings.min", values.bindings[code].min);
    setFieldValue("bindings.max", values.bindings[code].max);
    setOpenModal({ type: "addGroup", value: code });
  };

  const handleAddBinding = (code) => {
    setOpenModal({ type: "addBinding", value: code });
  };

  const handleSortEnd = (oldIndex, newIndex, bindings, setFieldValue) => {
    const orderedBindings = {};
    delete bindings.min;
    delete bindings.max;
    const sortedBindingList = Object.keys(bindings)
      .map((code) => ({
        code,
        ...bindings[code],
      }))
      .sort((b1, b2) => b1.index - b2.index);
    arrayMove(sortedBindingList, oldIndex, newIndex).forEach(
      (binding, index) =>
        (orderedBindings[binding.code] = { ...binding, index })
    );
    setFieldValue("bindings", orderedBindings);
  };

  const onAddGroup = ({ id, name, min, max }, { values, setFieldValue }) => {
    const bindings = { ...(values.bindings || {}) };
    if (id && bindings[id]) {
      bindings[id] = { ...bindings[id], name, min, max };
    } else {
      const id = `${Object.keys(bindings).length}`;
      bindings[id] = { name, min, max };
    }
    delete bindings.name;
    delete bindings.min;
    delete bindings.max;

    setFieldValue("bindings", bindings);
    setOpenModal();
  };
  const vatRates = getVatRates();
  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        _id: product?._id || "",
        isActive: product?.isActive || "",
        productName: product?.productName || "",
        productDescription: product?.productDescription || "",
        configuration: product?.configuration || "",
        price: product?.price || "",
        salePrice: product?.salePrice || "",
        categories: product?.categories || {},
        bindings: product?.bindings || {},
        image: product?.image || "",
        vatRate: product?.vatRate || "",
        stock: product?.stock || "",
        customProductId: product?.customProductId || "",
        sku: product?.sku || "",
        customProductImageUrl: product?.customProductImageUrl || "",
      }}
      validationSchema={DSOKProductSchema}
      onSubmit={async (data) => {
        const value = { ...data };
        delete data.bindings.name;
        const handler = product ? onEditProduct : onAddProduct;
        handler(value);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form style={{ textAlign: "left", maxHeight: "100%" }} noValidate>
          <Typography variant="h4" pl={2} pb={2} mt={0}>
            {title || ""}
          </Typography>
          <TabContext value={selectedTab} sx={{ maxHeight: "100%" }}>
            <Stack sx={{ mb: 2, maxHeight: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList onChange={(_, val) => setSelectedTab(val)}>
                  {PRODUCT_DETAIL_TABS.map((tab) => {
                    return (
                      <Tab
                        key={tab}
                        label={t(`pages.dsokProducts.${tab}`)}
                        value={tab}
                      />
                    );
                  })}
                </TabList>
              </Box>
              <TabPanel key="main" value="main" sx={{ pb: 1, pr: 0 }}>
                <Stack direction="row" gap={4} width="100%" alignItems="center">
                  <UploadComponent
                    name="image"
                    setFieldValue={setFieldValue}
                    value={values.image}
                  />
                  <Stack width="100%">
                    <Stack direction="row" gap={2} pl={2}>
                      <Grid container columnSpacing={2}>
                        <Grid item md={4} xs={12}>
                          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <FormControlLabel
                              control={
                                <Field
                                  name={`isActive`}
                                  component={Switch}
                                  onChange={(e) =>
                                    setFieldValue(`isActive`, e.target.checked)
                                  }
                                />
                              }
                              label={t("pages.products.active")}
                              checked={values.isActive}
                            />
                          </Div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                            <JumboTextField
                              fullWidth
                              name={`customProductId`}
                              label={t("pages.dsokProducts.customProductId")}
                            />
                          </Div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                            <JumboTextField
                              fullWidth
                              name={`_id`}
                              label={t("pages.dsokProducts.productId")}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                            <JumboTextField
                              fullWidth
                              name={`sku`}
                              label={t("pages.dsokProducts.sku")}
                            />
                          </Div>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                            <JumboTextField
                              fullWidth
                              name={`configuration`}
                              label={t("pages.dsokProducts.configuration")}
                            />
                          </Div>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                            <JumboTextField
                              fullWidth
                              name={`customProductImageUrl`}
                              label={t(
                                "pages.dsokProducts.customProductImageUrl"
                              )}
                            />
                          </Div>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {locations && (
                            <Typography variant="caption" color="gray" my={1}>
                              {t("pages.dsokProducts.stocks")}
                            </Typography>
                          )}
                          {Object.keys(locations)?.map((id) => {
                            const location = locations[id];
                            return (
                              <Box
                                key={id}
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                my={1}
                              >
                                <Typography variant="body">
                                  {location.code}
                                </Typography>
                                <Div sx={{ mt: 2, mb: 1, width: "50%" }}>
                                  <JumboTextField
                                    type="number"
                                    fullWidth
                                    name={`stock.${location.code}`}
                                    value={values.stock[location.code]}
                                    label={t("pages.dsokProducts.stock")}
                                    InputLabelProps={{ required: false }}
                                    InputProps={{
                                      inputProps: {
                                        min: 0,
                                      },
                                      onChange: (e) =>
                                        setFieldValue(
                                          `stock.${location.code}`,
                                          e.target.value
                                        ),
                                    }}
                                  />
                                </Div>
                              </Box>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Stack>
                    <TabContext value={selectedLanguage}>
                      <Stack sx={{ mb: 2 }}>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                          }}
                        >
                          <TabList
                            onChange={(_, val) => setSelectedLanguage(val)}
                            aria-label="lab API tabs example"
                          >
                            {productLanguages.map((lang) => {
                              return (
                                <Tab
                                  key={lang}
                                  label={t(`languages.${lang}`)}
                                  value={lang}
                                />
                              );
                            })}
                          </TabList>
                        </Box>
                        {productLanguages.map((lang) => {
                          return (
                            <TabPanel
                              key={lang}
                              value={lang}
                              sx={{ pb: 1, pr: 0 }}
                            >
                              <Div sx={{ mb: 1, mx: 0 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`productName.${lang}`}
                                  label={t("pages.blocks.moduleName")}
                                />
                              </Div>
                              <Typography variant="caption" color="gray" my={1}>
                                {t("pages.products.description")}
                              </Typography>
                              <CKEditor
                                onChange={(event) => {
                                  const data = event.editor.getData();
                                  setFieldValue(
                                    `productDescription.${lang}`,
                                    data
                                  );
                                }}
                                initData={
                                  (values.productDescription &&
                                    values.productDescription[lang]) ||
                                  ""
                                }
                                config={{
                                  contentsCss: [
                                    `${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                                    `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                  ],
                                  height: 75,
                                  extraPlugins:
                                    "font,colorbutton,justify,colordialog",
                                  fontSize_sizes: CK_FONT_SIZE_OPTIONS,
                                  colorButton_enableMore: true,
                                  versionCheck: false,
                                }}
                              />
                            </TabPanel>
                          );
                        })}
                      </Stack>
                    </TabContext>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel key="prices" value="prices" sx={{ pb: 1, pr: 0 }}>
                <Grid container columnSpacing={2}>
                  <Grid item md={4} xs={12}>
                    <Div sx={{ mt: 2, mb: 1, width: "100%" }}>
                      <JumboTextField
                        type="number"
                        fullWidth
                        name="price"
                        value={values.price}
                        label={t("pages.products.price")}
                        InputLabelProps={{ required: false }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          onChange: (e) =>
                            setFieldValue("price", e.target.value),
                        }}
                      />
                    </Div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Div sx={{ mt: 2, mb: 1, width: "100%" }}>
                      <JumboTextField
                        type="number"
                        fullWidth
                        name="salePrice"
                        value={values.salePrice}
                        label={t("pages.products.salePrice")}
                        InputLabelProps={{ required: false }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          onChange: (e) =>
                            setFieldValue("salePrice", e.target.value),
                        }}
                      />
                    </Div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Div sx={{ mt: 2, mb: 1, width: "100%" }}>
                      <JumboTextField
                        select
                        fullWidth
                        name="vatRate"
                        label={t("pages.dsokProducts.vatRate")}
                      >
                        {vatRates.map((rate) => {
                          return (
                            <MenuItem key={rate.key} value={rate.value}>
                              {rate.value}%
                            </MenuItem>
                          );
                        })}
                      </JumboTextField>
                    </Div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                key="categories"
                value="categories"
                sx={{
                  pb: 1,
                  height: "60vh",
                  pr: 0,
                }}
              >
                <Box
                  maxHeight="58vh"
                  height="100%"
                  px={1}
                  sx={{ overflowY: "scroll" }}
                >
                  <Div sx={{ display: "flex" }}>
                    <Card
                      sx={{
                        mb: 3,
                        mr: 2,
                        flex: 5,
                        boxShadow: `0 0.5rem 0.25rem ${alpha(
                          "#7352C7",
                          0.175
                        )}`,
                      }}
                    >
                      <JumboSearch
                        page="DSOKProductCategories"
                        onChange={setSearchName}
                        sx={{
                          width: "100%",
                        }}
                        placeholder={t("pages.presentation.searchPresentation")}
                      />
                    </Card>
                    <Div sx={{ mt: 0, mb: 0, mx: 0.5, flex: 1 }}>
                      <FormControlLabel
                        control={
                          <Field
                            name={`filter_only_active`}
                            component={Switch}
                            onChange={(e) =>
                              setOnlyActiveCategories(e.target.checked)
                            }
                          />
                        }
                        label={t("pages.dsokProducts.showActiveCategories")}
                        checked={onlyActiveCategories || false}
                      />
                    </Div>
                  </Div>
                  {filteredCategories?.map((category, index) => (
                    <DSOKCategoryCheckbox
                      key={index}
                      item={category}
                      searchName={searchName}
                      checked={
                        product?.categories
                          ? product?.categories[category._id]
                          : false
                      }
                      defaultLanguage={defaultLanguage}
                    />
                  ))}
                </Box>
              </TabPanel>
              <TabPanel
                key="bindings"
                value="bindings"
                sx={{
                  pb: 1,
                  pr: 0,
                  maxHeight: "60vh",
                  height: "60vh",
                }}
              >
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      size="normal"
                      variant="contained"
                      onClick={() => {
                        setFieldValue("bindings.name", {});
                        setFieldValue("bindings.min", 0);
                        setFieldValue("bindings.max", 0);
                        setOpenModal({ type: "addGroup" });
                      }}
                    >
                      {t("pages.dsokProducts.addGroup")}
                    </Button>
                  </Grid>
                  <Field
                    component={BindingItems}
                    bindings={values.bindings}
                    onSortEnd={({ oldIndex, newIndex }) =>
                      handleSortEnd(
                        oldIndex,
                        newIndex,
                        values.bindings,
                        setFieldValue
                      )
                    }
                    onAddGroup={handleAddGroup}
                    onAddBinding={handleAddBinding}
                    onDelete={handleDelete}
                    allProducts={allProducts}
                    useDragHandle={true}
                    defaultLanguage={defaultLanguage}
                  />
                </Grid>
              </TabPanel>
            </Stack>
          </TabContext>
          <Div
            sx={{
              mt: 2,
              mb: 1,
              mx: 2,
              gap: "4px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button size="normal" onClick={onCancel}>
              {t("buttons.cancel")}
            </Button>
            <Button type="submit" variant="contained" size="normal">
              {t("buttons.ok")}
            </Button>
          </Div>
          <Modal open={openModal !== undefined} onClose={() => setOpenModal()}>
            <Div sx={modalStyle}>
              {openModal?.type === "addGroup" && (
                <>
                  <TabContext value={selectedLanguage}>
                    <Stack sx={{ mb: 2 }}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                        }}
                      >
                        <TabList
                          onChange={(_, val) => setSelectedLanguage(val)}
                        >
                          {productLanguages.map((lang) => {
                            return (
                              <Tab
                                key={lang}
                                label={t(`languages.${lang}`)}
                                value={lang}
                              />
                            );
                          })}
                        </TabList>
                      </Box>
                      {productLanguages.map((lang) => {
                        return (
                          <TabPanel
                            key={lang}
                            value={lang}
                            sx={{ pb: 1, pr: 0 }}
                          >
                            <Div sx={{ mb: 1, mx: 0 }}>
                              <JumboTextField
                                fullWidth
                                required
                                name={`bindings.name.${lang}`}
                                label={t("pages.blocks.moduleName")}
                              />
                            </Div>
                          </TabPanel>
                        );
                      })}
                      <Grid container columnSpacing={2} ml={1}>
                        <Grid item md={4} xs={12}>
                          <Div sx={{ mt: 2, mb: 1, width: "100%" }}>
                            <JumboTextField
                              type="number"
                              fullWidth
                              name={`bindings.min`}
                              value={values.bindings.min || 0}
                              label={t("pages.dsokProducts.min")}
                              InputLabelProps={{ required: false }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                                onChange: (e) =>
                                  setFieldValue(`bindings.min`, e.target.value),
                              }}
                            />
                          </Div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Div sx={{ mt: 2, mb: 1, width: "100%" }}>
                            <JumboTextField
                              type="number"
                              fullWidth
                              name={`bindings.max`}
                              value={values.bindings.max || 0}
                              label={t("pages.dsokProducts.max")}
                              InputLabelProps={{ required: false }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                                onChange: (e) =>
                                  setFieldValue(`bindings.max`, e.target.value),
                              }}
                            />
                          </Div>
                        </Grid>
                      </Grid>
                    </Stack>
                  </TabContext>
                  <Div
                    sx={{
                      mt: 2,
                      mb: 1,
                      mx: 2,
                      gap: "4px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button size="normal" onClick={() => setOpenModal()}>
                      {t("buttons.cancel")}
                    </Button>
                    <Button
                      variant="contained"
                      size="normal"
                      onClick={() =>
                        onAddGroup(
                          {
                            id: openModal.value,
                            name: values.bindings.name,
                            min: values.bindings.min,
                            max: values.bindings.max,
                          },
                          {
                            values,
                            setFieldValue,
                          }
                        )
                      }
                    >
                      {t("buttons.ok")}
                    </Button>
                  </Div>
                </>
              )}
              {openModal?.type === "addBinding" && (
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Div sx={{ flex: 1, overflowY: "scroll", maxHeight: "60vh" }}>
                    <DSOKProducts
                      selectMode
                      onItemUpdate={(id, field, val) => {
                        let item =
                          values?.bindings &&
                          values?.bindings[openModal.value] &&
                          values?.bindings[openModal.value].products
                            ? values.bindings[openModal.value]?.products[id]
                            : {};
                        if (typeof item !== "object") {
                          item = {};
                        }
                        item[field] = val;
                        setFieldValue(`bindings.${openModal.value}.products`, {
                          ...(values.bindings[openModal.value]?.products || {}),
                          [id]: { ...item },
                        });
                      }}
                      selectedProducts={
                        values.bindings[openModal.value]?.products || {}
                      }
                    />
                  </Div>

                  <Div
                    sx={{
                      mt: 2,
                      mb: 1,
                      mx: 2,
                      gap: "4px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button size="normal" onClick={() => setOpenModal()}>
                      {t("buttons.cancel")}
                    </Button>
                    <Button
                      variant="contained"
                      size="normal"
                      onClick={() => setOpenModal()}
                    >
                      {t("buttons.ok")}
                    </Button>
                  </Div>
                </Div>
              )}
            </Div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default DSOKProductForm;
