import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { capitalizeFLetter, toFixed } from "@jumbo/utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { t } from "i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getVatRates } from "app/utils/appHelpers";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PersonOffIcon from "@mui/icons-material/PersonOff";

const StatusIcon = ({ status }) =>
  status ? (
    <CheckCircleIcon sx={{ color: "#2C9E4B" }} />
  ) : (
    <HighlightOffIcon sx={{ color: "#DA1113" }} />
  );
const DemoIcon = () => <RemoveCircleOutlineIcon sx={{ color: "#FFA500" }} />;
const UserCancelIcon = () => <PersonOffIcon sx={{ color: "#FFA500" }} />;
const ProductTable = ({ products, currencyCode, order }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = React.useMemo(
    () => products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page]
  );
  const vatRates = getVatRates();
  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: { md: 650, xs: 0 } }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("pages.blocks.moduleName")}</TableCell>
              <TableCell>{t("pages.dsokOrders.itemPrice")}</TableCell>
              <TableCell>{t("pages.dsokOrders.quantity")}</TableCell>
              <TableCell>{t("pages.dsokReports.VATRate")}</TableCell>
              <TableCell>{t("pages.dsokReports.totalWithoutVAT")}</TableCell>
              <TableCell>{t("pages.dsokReports.VAT")}</TableCell>
              <TableCell>{t("pages.dsokOrders.totalPrice")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>
                  {`${toFixed(row.itemPrice)} ${
                    (row.vatBase && currencyCode) || ""
                  }`}
                </TableCell>
                <TableCell>{row.qty}</TableCell>
                <TableCell>{row.vatRate}</TableCell>
                <TableCell>{`${toFixed(row.vatBase, 2)} ${
                  (row.vatBase && currencyCode) || ""
                }`}</TableCell>
                <TableCell>{`${toFixed(row.vat, 2)} ${
                  (row.vat && currencyCode) || ""
                }`}</TableCell>
                <TableCell>{`${toFixed(row.totalPrice, 2)} ${
                  (row.totalPrice && currencyCode) || ""
                }`}</TableCell>
              </TableRow>
            ))}
            {vatRates.map((rate) => {
              if (order[`${rate.key}`] || "") {
                return (
                  <>
                    <TableRow selected>
                      <TableCell component="th" scope="row">
                        {t("pages.dsokReports.VAT")} {`${rate.value}`} %
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{`${order[`${rate.key}Base`].toFixed(2)} ${
                        (order[`${rate.key}Base`].toFixed(2) && currencyCode) ||
                        ""
                      }`}</TableCell>
                      <TableCell>{`${order[`${rate.key}`].toFixed(2)} ${
                        (order[`${rate.key}`] && currencyCode) || ""
                      }`}</TableCell>
                      <TableCell>{`${
                        order[`${rate.key}Total`]
                          ? order[`${rate.key}Total`].toFixed(2)
                          : (
                              order[`${rate.key}`] + order[`${rate.key}Base`]
                            ).toFixed(2)
                      } ${
                        (order[`${rate.key}`] &&
                          order[`${rate.key}Base`] &&
                          currencyCode) ||
                        ""
                      }`}</TableCell>
                    </TableRow>
                  </>
                );
              }
            })}
            <TableRow selected>
              <TableCell component="th" scope="row">
                {t("pages.dsokOrders.total")}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                {`${toFixed(order.grandTotalWithoutVat, 2)} ${
                  (order.grandTotalWithoutVat && currencyCode) || ""
                }`}
              </TableCell>
              <TableCell>{`${toFixed(order.vatTotal, 2)} ${
                (order.vatTotal && currencyCode) || ""
              }`}</TableCell>
              <TableCell>
                {`${toFixed(order.grandTotal, 2)} ${
                  (order.grandTotal && currencyCode) || ""
                }`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

const GenericTable = ({ rows, type }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 15;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = React.useMemo(
    () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page]
  );

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: { md: 650, xs: 0 } }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("pages.dsokDetails.title")}</TableCell>
              <TableCell>{t("pages.dsokDetails.value")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => {
              const key =
                type === "ekasa" && row.key === "id" ? "UID" : row.key;
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

const GeneralInformation = ({
  t,
  values,
  order,
  currencyCode,
  demo,
  posUserCancel,
}) => (
  <Stack direction="column" gap={4} width="100%" alignItems="center">
    <fieldset disabled style={{ border: "none" }}>
      <Stack width="100%">
        <Stack direction="row" gap={2} pl={{ md: 2, xs: 0 }}>
          <Grid container columnSpacing={2}>
            <Grid item md={4} xs={12}>
              <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                <JumboTextField
                  fullWidth
                  name={`createdAt`}
                  label={t("pages.presentation.createdAt")}
                />
              </Div>
            </Grid>
            <Grid item md={4} xs={12}>
              <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                <JumboTextField
                  fullWidth
                  name={`orderStatus`}
                  label={t("pages.dsokOrders.orderStatus")}
                />
              </Div>
            </Grid>
            <Grid item md={4} xs={12}>
              <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                <JumboTextField
                  fullWidth
                  name={`pickupCode`}
                  label={
                    t("pages.dsokOrders.pickupCode") +
                    `${
                      order?.locator ? `/${t("pages.dsokOrders.locator")}` : ""
                    }`
                  }
                />
              </Div>
            </Grid>
            <Grid item md={4} xs={12}>
              <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                <JumboTextField
                  fullWidth
                  name={`orderCode`}
                  label={t("pages.dsokOrders.orderCode")}
                />
              </Div>
            </Grid>
            <Grid item md={4} xs={12}>
              <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                <JumboTextField
                  fullWidth
                  name={`grandTotal`}
                  label={t("pages.dsokOrders.total")}
                />
              </Div>
            </Grid>
            <Grid container item md={4} xs={12} columnSpacing={2}>
              <Grid
                item
                pb={2}
                md={4}
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    margin: "0 0 0 10px",
                    background: "white",
                    color: "text.secondary",
                    width: "fit-content",
                  }}
                >
                  {t("pages.dsokOrders.orderSent")}
                  <span
                    style={{
                      display: "inline",
                    }}
                  >
                    {order?.internalOrderId && `/${t("pages.presentation.id")}`}
                  </span>
                </Typography>
                <Typography
                  variant={"body1"}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  {posUserCancel && <UserCancelIcon />}
                  {!posUserCancel && (
                    <>
                      <StatusIcon status={order?.orderSentStatus} />
                      {order?.internalOrderId}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                pb={2}
                md={4}
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    margin: "0 0 0 10px",
                    background: "white",
                    color: "text.secondary",
                    width: "fit-content",
                  }}
                >
                  {t("pages.dsokOrders.payment")}
                </Typography>
                {posUserCancel && <UserCancelIcon />}
                {!posUserCancel && demo && <DemoIcon />}
                {!posUserCancel && !demo && (
                  <StatusIcon status={values?.posStatus} />
                )}
              </Grid>
              <Grid
                item
                pb={2}
                md={4}
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    margin: "0 0 0 10px",
                    background: "white",
                    color: "text.secondary",
                    width: "fit-content",
                  }}
                >
                  {t("pages.dsokDetails.ekasa")}
                </Typography>
                {posUserCancel && <UserCancelIcon />}
                {!posUserCancel && demo && <DemoIcon />}
                {!posUserCancel && !demo && (
                  <StatusIcon status={values?.ekasaStatus} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </fieldset>
    <Div
      sx={{
        mb: { md: 1, xs: 0 },
        mt: { md: 2, xs: 0 },
        mr: { md: 2, xs: 0 },
        ml: { md: 4, xs: 0 },
        width: "100%",
      }}
    >
      <ProductTable
        order={order}
        products={Object.values(order?.products || {})}
        currencyCode={currencyCode}
      />
    </Div>
  </Stack>
);

const EKASA = ({ order }) => {
  const selectedResponse = order.eKasaResponse || order.eKasaError || {};
  return (
    <Stack direction="row" gap={4} width="100%" alignItems="center">
      <Stack width="100%">
        <Stack direction="row" gap={2} pl={2}>
          <Grid container columnSpacing={2}>
            <Grid item md={12} xs={12}>
              <GenericTable
                rows={Object.keys(selectedResponse).map((key) => ({
                  key,
                  value:
                    typeof selectedResponse[key] !== "object" &&
                    selectedResponse[key],
                }))}
                type="ekasa"
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

const POS = ({ order }) => {
  const selectedResponse = order.posResponse || order.posError || {};
  return (
    <Stack direction="row" gap={4} width="100%" alignItems="center">
      <Stack width="100%">
        <Stack direction="row" gap={2} pl={2}>
          <Grid container columnSpacing={2}>
            <Grid item md={12} xs={12}>
              <GenericTable
                rows={Object.keys(selectedResponse).map((key) => ({
                  key,
                  value:
                    typeof selectedResponse[key] !== "object" &&
                    selectedResponse[key],
                }))}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

const DSOKOrderForm = ({ order, currencyCode, title }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("generalInformation");
  const demo = order?.demo;
  let posUserCancel = false;
  if (
    order?.posError?.reason &&
    order?.posError?.reason === "EMV_USER_CANCEL"
  ) {
    posUserCancel = true;
  }
  const paymentErrorResponse = order?.posError?.paymentErrorResponse;
  let TABS = { generalInformation: GeneralInformation, pos: POS, ekasa: EKASA };
  if (demo) {
    TABS = { generalInformation: GeneralInformation };
  }
  if (posUserCancel) {
    TABS = { generalInformation: GeneralInformation, pos: POS };
  }
  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          createdAt:
            moment(order?.createdAt).format("DD/MM/YYYY HH:mm:ss") || "",
          orderStatus:
            (order?.orderStatus && capitalizeFLetter(order?.orderStatus)) || "",
          posStatus: order?.posStatus || "",
          orderSentStatus: order?.orderSentStatus || "",
          ekasaStatus: order?.ekasaStatus || "",
          pickupCode:
            (order?.pickupCode || "") +
            `${order?.locator ? `/${order?.locator}` : ""}`,
          orderCode: order?.orderCode || "",
          grandTotal:
            `${toFixed(order?.grandTotal, 2)} ${currencyCode || ""}` || "",
        }}
      >
        {({ setFieldValue, values }) => (
          <Form style={{ textAlign: "left" }} noValidate>
            <Typography variant="h4" pl={2} pb={2} mt={0}>
              {title || ""}
            </Typography>
            <TabContext value={selectedTab}>
              <Stack sx={{ mb: 2 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList
                    variant="scrollable"
                    visibleScrollbar
                    onChange={(_, val) => setSelectedTab(val)}
                  >
                    {Object.keys(TABS).map((tab) => {
                      return (
                        <Tab
                          key={tab}
                          label={
                            <Div
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {t(`pages.dsokDetails.${tab}`)}
                              {tab !== "generalInformation" &&
                                !posUserCancel && (
                                  <StatusIcon status={values[`${tab}Status`]} />
                                )}
                              {tab !== "generalInformation" &&
                                posUserCancel && (
                                  <UserCancelIcon
                                    status={values[`${tab}Status`]}
                                  />
                                )}
                            </Div>
                          }
                          value={tab}
                        />
                      );
                    })}
                  </TabList>
                </Box>
                {Object.keys(TABS).map((tab) => {
                  const SelectedTab = TABS[tab];
                  return (
                    <TabPanel
                      key={tab}
                      value={tab}
                      sx={{
                        pb: { md: 1, xs: 0 },
                        pr: 0,
                        pl: { md: 3, xs: 0 },
                        minHeight: 350,
                      }}
                    >
                      <SelectedTab
                        order={order}
                        values={values}
                        t={t}
                        currencyCode={currencyCode}
                        demo={demo}
                        posUserCancel={posUserCancel}
                      />
                    </TabPanel>
                  );
                })}
              </Stack>
            </TabContext>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DSOKOrderForm;
