import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, useFormikContext } from "formik";
import { Label } from "app/pages/presentation/module/form/types";
import {
  BE_DOMAINS,
  DSOK_LANGUAGES,
  CURRENCY_CODE_POSITIONS,
  FRONTEND_TYPE,
  CURRENCY_CODES,
  VAT_COUNTRY_CODES,
} from "app/utils/constants/settings";
import * as Yup from "yup";

const FIELD_NAME = "DSOK_CUSTOMER_FIELD";
export const DSOK_CUSTOMER_FIELD_SCHEMA = {
  storeCode: "",
  customerId: "",
  code: "",
  languages: [],
  defaultLanguage: "",
  displayLanguageSwitcher: "",
};

export const getDsokCustomerFieldValidationSchema = (t) => ({
  storeCode: Yup.string().required(t("messages.checkRequiredFields")),
  customerId: Yup.number().required(t("messages.checkRequiredFields")),
  code: Yup.string().required(t("messages.checkRequiredFields")),
  languages: Yup.array()
    .min(1, t("messages.checkRequiredFields"))
    .required(t("messages.checkRequiredFields")),
  defaultLanguage: Yup.string().required(t("messages.checkRequiredFields")),
});

const DsokCustomerField = ({ handleChange, expanded, isPartnerAdmin }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const isVatPayer = values?.customer?.vatPayer;
  const frontendType = values?.customer?.frontendType;

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.customerSettings")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                required
                name="customer.storeCode"
                label={t("pages.dsokDetails.storeCode")}
                InputProps={{
                  readOnly: isPartnerAdmin,
                }}
              />
            </Div>
          </Grid>
          {!isPartnerAdmin && (
            <Grid item xs={12} md={4} lg={4}>
              <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                <JumboTextField
                  type="number"
                  InputLabelProps={{ required: false }}
                  required
                  fullWidth
                  name="customer.customerId"
                  label={
                    <Label text={t("pages.dsokDetails.customerId")} required />
                  }
                  InputProps={{
                    value: values.customer?.customerId,
                    readOnly: true,
                    onChange: (e) =>
                      setFieldValue("customer.customerId", e.target.value),
                  }}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={4} lg={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                required
                name="customer.code"
                label={t("pages.dsokDetails.code")}
                InputProps={{
                  readOnly: isPartnerAdmin,
                }}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                select
                required
                InputLabelProps={{ required: false }}
                name="customer.languages"
                SelectProps={{
                  multiple: true,
                }}
                InputProps={{
                  readOnly: isPartnerAdmin,
                }}
                label={
                  <Label text={t("pages.dsokDetails.languages")} required />
                }
              >
                {DSOK_LANGUAGES.map((language, index) => {
                  return (
                    <MenuItem key={index} value={language.code}>
                      {language.name}
                    </MenuItem>
                  );
                })}
              </JumboTextField>
            </FormControl>
          </Grid>
          {!isPartnerAdmin && (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    select
                    required
                    InputLabelProps={{ required: false }}
                    name="customer.defaultLanguage"
                    label={
                      <Label
                        text={t("pages.dsokDetails.defaultLanguage")}
                        required
                      />
                    }
                  >
                    {DSOK_LANGUAGES.map((language, index) => {
                      return (
                        <MenuItem key={index} value={language.code}>
                          {language.name}
                        </MenuItem>
                      );
                    })}
                  </JumboTextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Div sx={{ mt: 1, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="customer.displayLanguageSwitcher"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "customer.displayLanguageSwitcher",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={t("pages.dsokDetails.displayLanguageSwitcher")}
                    checked={values.customer?.displayLanguageSwitcher}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    required
                    select
                    InputLabelProps={{ required: false }}
                    name={`customer.beDomain`}
                    label={
                      <Label text={t("pages.dsokDetails.beDomain")} required />
                    }
                  >
                    {BE_DOMAINS.map((domain) => {
                      return (
                        <MenuItem key={domain} value={domain}>
                          {domain}
                        </MenuItem>
                      );
                    })}
                  </JumboTextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    // required
                    select
                    // InputLabelProps={{ required: true }}
                    name={`customer.currencyCode`}
                    label={
                      <Label
                        text={t("pages.dsokDetails.currencyCode")}
                        required
                      />
                    }
                  >
                    {CURRENCY_CODES.map(({ code, name }) => {
                      return (
                        <MenuItem key={code} value={code}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </JumboTextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    required
                    select
                    InputLabelProps={{ required: false }}
                    name={`customer.frontendType`}
                    label={<Label text={t("pages.dsokDetails.frontendType")} />}
                  >
                    {FRONTEND_TYPE.map((item) => {
                      return (
                        <MenuItem key={item?.code} value={item?.code}>
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </JumboTextField>
                </FormControl>
              </Grid>
              {frontendType === "easyPay" && frontendType && (
                <>
                  <Grid item xs={12} md={2} lg={2}>
                    <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                      <JumboTextField
                        required
                        select
                        InputLabelProps={{ required: false }}
                        name={`customer.currencyCodePosition`}
                        label={
                          <Label
                            text={t("pages.dsokDetails.currencyCodePosition")}
                          />
                        }
                      >
                        {CURRENCY_CODE_POSITIONS.map((item) => {
                          return (
                            <MenuItem key={item?.code} value={item?.code}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </JumboTextField>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12} lg={12}></Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="customer.vatPayer"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue("customer.vatPayer", e.target.checked)
                        }
                      />
                    }
                    label={t("pages.dsokDetails.vatPayer")}
                    checked={values.customer?.vatPayer}
                  />
                </Div>
              </Grid>
              {isVatPayer && (
                <>
                  <Grid item xs={12} md={2} lg={2}>
                    <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                      <JumboTextField
                        required
                        select
                        InputLabelProps={{ required: false }}
                        name={`customer.vatCountryCode`}
                        label={
                          <Label text={t("pages.dsokDetails.vatCountryCode")} />
                        }
                      >
                        {VAT_COUNTRY_CODES.map((item) => {
                          return (
                            <MenuItem key={item?.code} value={item?.code}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </JumboTextField>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={2} lg={2}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    required
                    name="customer.fiskalProPartnerId"
                    label={t("pages.dsokDetails.fiskalProPartnerId")}
                  />
                </Div>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DsokCustomerField;
