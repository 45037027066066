import React from "react";
import {
  Grid,
  Typography,
  Divider,
  FormControlLabel,
  MenuItem,
  FormControl,
  Alert,
} from "@mui/material";
import { Field } from "formik";
import Switch from "@mui/material/Switch";
import Div from "@jumbo/shared/Div";
import { Label } from "app/pages/presentation/module/form/types";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { DSOK_SUPPLIERS } from "app/utils/constants/settings";

const PosSection = ({ device, setFieldValue, values, t, posSupplier }) => {
  let info;
  switch (posSupplier) {
    case "besteron":
      info = t("pages.dsokDetails.infoTexts.pos.besteron");
      break;
    case "fiskalPro":
      info = t("pages.dsokDetails.infoTexts.pos.fiskalPro");
      break;
    case "iKelpPosManager":
      info = t("pages.dsokDetails.infoTexts.pos.iKelpPosManager");
      break;
    default:
      info = "";
  }
  const posPaymentEnabled =
    values.customer.devices[device].orderProcess?.enablePosPayment;
  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.pos")}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
          <FormControlLabel
            control={
              <Field
                name={`customer.devices.${device}.orderProcess.enablePosPayment`}
                component={Switch}
                onChange={(e) =>
                  setFieldValue(
                    `customer.devices.${device}.orderProcess.enablePosPayment`,
                    e.target.checked
                  )
                }
              />
            }
            label={
              <Label
                text={t("pages.dsokDetails.enablePosPayment")}
                color="primary"
                helpText={t("pages.dsokDetails.enablePosPaymentHelp")}
              />
            }
            checked={
              values.customer.devices[device].orderProcess?.enablePosPayment
            }
          />
        </Div>
      </Grid>
      {posPaymentEnabled && (
        <>
          <Grid item xs={12} md={4} lg={4}>
            <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
              <FormControlLabel
                control={
                  <Field
                    name={`customer.devices.${device}.pos.useHTTPS`}
                    component={Switch}
                    onChange={(e) =>
                      setFieldValue(
                        `customer.devices.${device}.pos.useHTTPS`,
                        e.target.checked
                      )
                    }
                  />
                }
                label={t("pages.dsokDetails.useHTTPS")}
                checked={values.customer.devices[device].pos?.useHTTPS}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                required
                select
                InputLabelProps={{ required: false }}
                name={`customer.devices.${device}.pos.supplier`}
                label={
                  <Label text={t("pages.dsokDetails.supplier")} required />
                }
              >
                {DSOK_SUPPLIERS.map(({ code, name }) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </JumboTextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Alert variant="outlined" severity="info">
              {info}
            </Alert>
          </Grid>
          <Grid item xs={12} md={12} lg={12}></Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                required
                fullWidth
                name={`customer.devices.${device}.pos.ip`}
                label={t("pages.dsokDetails.ip")}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                required
                name={`customer.devices.${device}.pos.localPort`}
                label={t("pages.dsokDetails.localPort")}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                required
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                name={`customer.devices.${device}.pos.serverPort`}
                label={t("pages.dsokDetails.serverPort")}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}></Grid>
          {posSupplier === "besteron" && (
            <>
              <Grid item xs={12} md={2} lg={2}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    required
                    name={`customer.devices.${device}.pos.poiId`}
                    label={t("pages.dsokDetails.poiId")}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    name={`customer.devices.${device}.pos.username`}
                    label={t("pages.login.username")}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    name={`customer.devices.${device}.pos.password`}
                    label={t("pages.login.password")}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    name={`customer.devices.${device}.pos.serialNumber`}
                    label={t("pages.dsokDetails.posSerialNumber")}
                  />
                </Div>
              </Grid>
            </>
          )}
          {posSupplier === "iKelpPosManager" && (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    InputLabelProps={{ required: false }}
                    name={`customer.devices.${device}.pos.posCode`}
                    label={
                      <Label
                        text={t("pages.dsokDetails.posCode")}
                        color="primary"
                        helpText={t("pages.dsokDetails.posCodeHelpText")}
                        required
                      />
                    }
                  />
                </Div>
              </Grid>
            </>
          )}
          {posSupplier === "fiskalPro" && (
            <>
              <Grid item xs={12} md={4} lg={2}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    name={`customer.devices.${device}.pos.serialNumber`}
                    label={t("pages.dsokDetails.posSerialNumber")}
                  />
                </Div>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PosSection;
